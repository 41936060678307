code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-small {
  font-size: 0.8em;
  font-weight: bold;
}

.small {
  font-size: 0.8em;
}